<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <!-- Toolbar -->
        <v-toolbar color="white">
          <v-toolbar-title>
            <v-chip color="orange" text-color="white" label>
              <h3>Payment</h3>
            </v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- Centered and Conditionally Styled Button/Chip -->
        <v-container fluid class="d-flex justify-center" style="height: 100vh;">
          <div>
            <v-btn
              @click="startBillingProcess"
              color="blue"
              dark
              elevation="12"
              rounded
              large
              class="px-12 py-4"
            >
              <v-icon left>mdi-cash</v-icon>
              Start Billing Process
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import Util from "@/js/Util";
import WorkOrderDB from "@/js/database/workorder_db";
class MomentPay {
  constructor() {
    this.overlay = null;
    this.iframe = null;
    this.onCloseCallback = null;
    this.onResponseCallback = null;
  }

  init(params) {
    this.createOverlay();
    this.createCloseButton();
    this.createIframe(params);
  }

  createOverlay() {
    this.overlay = document.createElement("div");
    this.setStyle(this.overlay, {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: "10000",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    });
    document.body.appendChild(this.overlay);
  }

  createCloseButton() {
    const closeButton = document.createElement("button");
    closeButton.innerHTML = "&#x2715;";
    this.setStyle(closeButton, {
      position: "absolute",
      top: "20px",
      right: "20px",
      zIndex: "10001",
      cursor: "pointer",
      border: "2px solid red",
      borderRadius: "50%",
      width: "30px",
      height: "30px",
      lineHeight: "24px",
      textAlign: "center",
      backgroundColor: "white",
      color: "red",
      fontSize: "20px",
    });
    closeButton.onclick = () => this.closeIframe();
    this.overlay.appendChild(closeButton);
  }

  createIframe(params) {
    this.iframe = document.createElement("iframe");
    this.setStyle(this.iframe, {
      width: "60%",
      height: "80%",
      border: "none",
    });
    this.overlay.appendChild(this.iframe);

    window.requestAnimationFrame(() => this.setupFormInIframe(params));
  }

  setupFormInIframe(params) {
    const doc =
      this.iframe.contentDocument || this.iframe.contentWindow.document;
    doc.open();
    doc.close();

    const form = doc.createElement("form");
    form.method = "post";
    form.action = params.actionUrl;
    doc.body.appendChild(form);

    this.addHiddenInput(doc, form, "token", JSON.stringify(params.token));
    this.addHiddenInput(doc, form, "mid", params.mid);
    this.addHiddenInput(doc, form, "check_sum_hash", params.checkSumHash);
    form.submit();
  }

  addHiddenInput(doc, form, name, value) {
    const input = doc.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    form.appendChild(input);
  }

  setStyle(element, styles) {
    for (const property in styles) {
      if (Object.hasOwnProperty.call(styles, property)) {
        element.style[property] = styles[property];
      }
    }
  }

  closeIframe() {
    if (this.overlay) {
      document.body.removeChild(this.overlay);
      this.overlay = null;
      this.iframe = null;
      if (typeof this.onCloseCallback === "function") {
        this.onCloseCallback();
      }
    }
  }

  onClose(callback) {
    this.onCloseCallback = callback;
  }

  onResponse(callback) {
    window.addEventListener(
      "message",
      (event) => {
        if (typeof callback === "function") {
          callback(event.data);
          this.closeIframe();
        }
      },
      false
    );
  }
}

const paymentMoment = new MomentPay();

export default {
  props: ["show", "item"],
  data() {
    return {
      dialog: this.show,
      payment: this.item,

      loading: false,
    };
  },

  watch: {
    show(val) {
      this.dialog = val;
    },
    dialog(val) {
      if (!val) this.$emit("close_payment");
    },
  },

  mounted() {
    window.addEventListener(
      "message",
      (event) => {
        console.log("Testing");
        console.log(event);

        const data = event.data;

        if (
          !data ||
          !data.response ||
          !data.response.code ||
          !data.response.data.amount
        ) {
          console.warn("Invalid data received or missing required fields.");
          return;
        }

        console.log(data);
        console.log("Amount:", data.response.data.amount);
        console.log("Response Code:", data.response.code);

        // Call the method with the correct context
        this.handlePaymentResponse(data.response);
      },
      false
    );
  },

  methods: {
    close() {
      this.dialog = false;
    },
    set_doc: function(doc) {
      Util.setSession("current_doc", JSON.stringify(doc));
    },
    async handlePaymentResponse(response) {
      const time_stamp = Util.gettime();
      let doc = this.payment;
      console.log(doc);
       let db = await WorkOrderDB.get_server_db("work_orders");
      doc.payment_status_updated_by = Util.getFromSession("logged_in_emp_name");
      doc.payment_status_updated_at = time_stamp;

      if (response.code === "PAYMENT_SUCCESS") {
        console.log("Transaction successful!");
        doc.payment_response_code = response.code;
        doc.payment_amount = response.data.amount;
      } else if (response.code === "PAYMENT_FAILED") {
        console.log("Transaction failed!");
        doc.payment_response_code = response.code;
      } else {
        doc.payment_response_code = "pending";
        console.warn("Unhandled response code received:", response.code);
        //return;
      }

     
      db.put(doc)
        .then(function(response) {
          console.log("Status updated in local DB:", response);
          if (response.ok) {
            console.log("Document successfully updated.");
          }
        })
        .catch(function(err) {
          console.error("Error updating the document:", JSON.stringify(err));
        });
    },

    getProcessingId() {
      return this.payment._id.slice(-6); // extracts the last 6 characters
    },
    generateChecksum(amount, processing_id, merchant_id) {
      console.log("Generating checksum...");
      console.log("Amount:", amount);
      console.log("Processing ID:", processing_id);
      console.log("Merchant ID:", merchant_id);
      const Merchant_Secret_key =
        "jQuI1FOG44Yc8N7P2FuspUKTdt89OsioKtKs19B4ntfTeMNj98";
      const stringToHash = `${amount}|${processing_id}|${merchant_id}|${Merchant_Secret_key}`;
      return CryptoJS.SHA256(stringToHash).toString(CryptoJS.enc.Base64);
    },
    startBillingProcess() {
      this.loading = true;
      const processing_id = this.getProcessingId();
      const merchant_id = "24"; // Example merchant ID, replace if needed.
      const amount = this.payment.amount_received;

      // Generate checksum
      var checksum = this.generateChecksum(amount, processing_id, merchant_id);
      console.log(checksum);
      const params = {
        actionUrl: "https://testing.momentpay.in/ma/v2/extended-iframe-payment",
        token: {
          auth: {
            user: "andersondiagnostics_test",
            key: "n0tKMr09pVykMbb7k4ua9cJYxQ2CjXMg6p0Rq35UVTK7nMamea",
          },
          cashier_id: "1",
          uname: "1",
          accounts: [
            {
              patient_name: this.payment.name,
              account_number: "1234",
              amount: this.payment.amount_received,
              email: this.payment.email,
              phone: this.payment.mobile,
            },
          ],
          processing_id: this.getProcessingId(),

          paymode: "mobile-app",
          payment_fill: "pre_full",
          payment_location: "Test Hospital",
          return_url: "https://testapp.ariticapp.com/ma/thankyou-new",
          response_url: "https://testapp.ariticapp.com/ma/thankyou-new",
        },
        mid: "24",
        checkSumHash: checksum,
      };
      console.log(params.mid);
      console.log(params.checkSumHash);
      paymentMoment.init(params);
    },
  },
};
</script>
