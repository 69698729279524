<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="tableData"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :items-per-page="100"
      item-key="_id"
      show-expand
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @keyup.native="debounce_search()"
          ></v-text-field>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        {{ item.name }} &nbsp;
        <v-chip color="red" outlined x-small v-if="getClientStatus(item)">
          {{ getClientStatus(item) }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="blue"
          class="mx-2"
          elevation="2"
          icon
          x-small
          @click="bill_order(item)"
          v-if="checkstatus(item)"
        >
          <v-icon dark> mdi-calendar-start </v-icon>
        </v-btn>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" outlined x-small>
          {{ item.status }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.server_status="{ item }">
        <v-chip :color="getColor(item.server_status)" outlined x-small>
          {{ getStatus(item.server_status) }}
        </v-chip>
      </template>

    <template v-slot:item.send="{ item }">
        <v-btn
          color="green"
          class="mx-2"
          elevation="2"
          icon
          x-small
          @click="confirm_send_item(item)"
          v-if="check_status_send(item)"
        >
          <v-icon dark> mdi-send </v-icon>
        </v-btn>

        <v-chip v-if="check_status_chip(item)" color="primary" outlined x-small>
          sent
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          Address : {{ item.address }}<br />
          Pincode : {{ item.pincode }}<br />
          Ref By: {{ item.doctor_name }}<br />
          Prescription:
          <v-chip
            color="blue"
            outlined
            x-small
            v-if="item.pres_photo"
            @click="download_files(item.pres_photo)"
          >
            {{ get_name(item.pres_photo) }} </v-chip
          ><br />
          <div v-if="item.process">
            Prescription Photo:
            <v-chip
              v-if="item.process.fifth_step"
              color="primary"
              outlined
              x-small
              @click="download_files(item.process.fifth_step)"
            >
              {{ getPrescrptionFileNames(item.process.fifth_step) }} </v-chip
            ><br />
            Proforma Invoice:
            <v-chip
              v-if="item.process.second_step"
              color="primary"
              outlined
              x-small
              @click="download_files(item.process.second_step)"
            >
              Proforma Invoice
            </v-chip>
          </div>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Dept. Id</th>
                  <th class="text-left">Dept. Name</th>
                  <th class="text-left">Investigation Id</th>
                  <th class="text-left">Investigation Name</th>
                  <th class="text-left">Base Cost</th>
                  <th class="text-left">Min. Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="thing in item.test_items" :key="thing.invest_id">
                  <td>{{ thing.dept_id }}</td>
                  <td>{{ thing.dept_name }}</td>
                  <td>{{ thing.invest_id }}</td>
                  <td>{{ thing.invest_name }}</td>
                  <td>{{ thing.base_cost }}</td>
                  <td>{{ thing.min_cost }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          Total : ₹{{ item.total }}<br />
        </td>
      </template>
    </v-data-table>
    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h6 orange lighter-4 white--text"
          >Confirmation</v-card-title
        >
        <v-alert dense text type="warning"
          >Are you sure you want to send?</v-alert
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="send_confirmed_item()"
            >Yes</v-btn
          >
          <v-btn color="red darken-1" text @click="confirmDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 
      Cancel Dialog   
    -->

    <div>
      <v-dialog v-model="billDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text">
            Enter Billing Details
          </v-card-title>
          <v-card-text>
            <v-form ref="enter_bill_form" v-model="valid" lazy-validation>
              <v-text-field
                ref="bill_no_text_field"
                label="Bill Number"
                hint="Bill Number"
                v-model="bill_number"
                :rules="billRules"
                required
              ></v-text-field>
              <v-text-field
                ref="lab_no_text_field"
                label="Lab Number"
                hint="Lab Number"
                v-model="lab_number"
                :rules="labRules"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="billDialog = false">
              Cancel
            </v-btn>
            <v-btn color="orange darken-1" text @click="doBillRegister()">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view / download
   -->
    <div>
      <v-dialog
        persistent
        v-model="file_dialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="orange" dark>View / Download Files</v-toolbar>
          <v-card-text>
            <table>
              <tr v-for="url in pres_array" :key="url">
                <td>
                  <v-btn text color="primary"> {{ get_name(url) }}</v-btn>
                </td>
                <td>
                  <v-btn text color="primary" @click="download_id(url, 'view')"
                    >View
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    text
                    color="primary"
                    @click="download_id(url, 'download')"
                    >Download</v-btn
                  >
                </td>
              </tr>
            </table>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="close_download_dialog">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view pdf
   -->
    <v-container v-if="show_report">
      <show_pdf
        v-bind:show_report="show_report"
        v-bind:s3_url="s3_url"
        @close_pdf="pdf_close()"
      ></show_pdf>
    </v-container>
    <v-container v-if="show_img">
      <show_img
        v-bind:show_img="show_img"
        v-bind:s3_url="s3_url"
        @close_image="img_close()"
      ></show_img>
    </v-container>

    <v-overlay :value="overlay_dk" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
//import DBHandler from "@/js/database/DBHandler";
import WorkOrderDB from "@/js/database/workorder_db";
import Util from "@/js/Util";
import { mapState } from "vuex";
import fileDownload from "js-file-download";
import axios from "axios";
import settings from "@/js/database/settings";
import show_pdf from "@/components/show_pdf.vue";
import show_img from "@/components/show_img.vue";
import couch_db from "@/js/database/couch_db";

import moment from "moment";
export default {
  name: "BillingWorkOrders",
  props: ["type"],
  components: { show_pdf, show_img },
  data() {
    return {
      loading: false,
      expanded: [],
      singleExpand: true,
      search: "",
      tableHeaders: [
        { text: "Name", align: "start", sortable: true, value: "_id" },
        { text: "Gender", value: "gender" },
        { text: "Age", value: "age" },
        { text: "Mobile", value: "mobile" },
        { text: "Date", value: "appointment_date" },
        { text: "Time", value: "appointment_time" },
        { text: "Total (₹)", value: "total" },
        { text: "Assigned", value: "assigned_to" },
        { text: "Tech. Status", value: "status" },
        { text: "Server Status", value: "server_status" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "Send", value: "send", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      tableData: [],
      work_orders: [],
      snackbar: "",
      snackbar_text: "",
      valid: false,
      item: null,
      bill_item: null,
      billDialog: false,
      bill_number: "",
      billRules: [
        (v) => !!v || "Bill Number is required",
        (v) =>
          (v && v.length > 12) ||
          "Bill Number Must Be Greater Than 12 Characters",
      ],
      lab_number: "",
      labRules: [
        (v) => !!v || "Lab Number is required",
        (v) =>
          (v && v.length > 8) || "Lab Number Must Be Greater Than 8 Characters",
      ],
      file_dialog: false,
      pres_array: [],
      show_report: false,
      s3_url: "",
      show_img: false,
      overlay_dk: false,
      confirmDialog: false,
    };
  },
  computed: mapState(["work_order"]),
  watch: {
    work_order(newValue, oldValue) {
      console.log("Updating from " + oldValue + " to " + newValue);
      this.updateScreen();
    },
  },
  mounted: async function() {
    this.updateScreen();
  },
  methods: {
    updateScreen: async function() {
      this.loading = true;
      if (this.type == "billed") {
        this.work_orders = await WorkOrderDB.billed_list("Finished", "Billed");
      } else {
        this.work_orders = await WorkOrderDB.billed_list(
          "Finished",
          "Received"
        );
      }
      this.tableData = this.work_orders;
      this.loading = false;
    },
    checkstatus: function(item) {
      let status = item.status;
      let server_status = item.server_status;
      if (status == "NA") {
        return false;
      } else if (status == "Finished" && server_status == "Received") {
        return true;
      } else if (status == "cancelled") {
        return false;
      }
      return false;
    },
 check_status_send: function(item) {
      let status = item.status;
      let server_status = item.server_status;
      let sent_status = item.sent_status;
      if (status == "NA") {
        return false;
      } else if (
        status == "Finished" &&
        server_status == "Received" &&
        sent_status != "sent"
      ) {
        return true;
      } else if (status == "cancelled") {
        return false;
      }
      return false;
    },
     check_status_chip: function(item) {
      let status = item.status;
      let server_status = item.server_status;
      let sent_status = item.sent_status;
      if (status == "NA") {
        return false;
      } else if (
        status == "Finished" &&
        server_status == "Received" &&  sent_status == "sent"
      ) {
        return true;
      } else if (status == "cancelled") {
        return false;
      }
      return false;
    },


    confirm_send_item(item) {
      this.selected = item;
      this.confirmDialog = true;
    },

    send_confirmed_item: async function() {
      console.log("Success");
      let role_list = Util.getFromSession("role_list");
      console.log(role_list);
      let check_role_list = Util.getFromSession("check_role_list");
      console.log(check_role_list);
      let final_role = Util.getFromSession("final_role");
      console.log(final_role);

      let created_by = Util.getFromSession("logged_in_emp_id");
      let test_items = this.selected.test_items;
      let age = parseInt(this.selected.age, 10);
      if (isNaN(age)) {
        age = 0;
      }

      let data = JSON.stringify({
        patient: {
          firstName: this.selected.name,
          lastName: "null",
          age: age,
          period: 3,
          mobileNumber: this.selected.mobile,
          addressLine1: this.selected.address,
          postalCode: this.selected.pincode,
        },
        clientName: "null",
        clientId: 0,
        investigations: test_items.map((test) => ({
          investigationId: test.invest_id,
          investigationName: test.invest_name,
          amount: test.base_cost,
          discount: 0,
        })),
        payments: [
          {
            amount: this.selected.amount_received,
          },
        ],
        createdBy: created_by,
        remarks: this.selected.remarks,
      });

      console.log(data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api",
        headers: {
          "Content-Type": "application/json",
          "x-static-key":
            "oxJvBOWYwwByrHlmaf/7tQsy5V7X9Q2Bez8rCeVMeP073A07UzBajHu9S+1DTQAJ",
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        this.snackbar = true;
        if (response.status === 200) {
          console.log("sent successfully ", response);
          this.snackbar_text = "Sent Successfully";

          // Update sent status in the local WorkOrderDB
          let time_stamp = Util.gettime();
          let doc = this.selected;
          doc.sent_status = "sent";
          doc.sent_status_updated_by = Util.getFromSession(
            "logged_in_emp_name"
          );
          doc.sent_status_updated_at = time_stamp;

          let instance = this;
          let db = await WorkOrderDB.get_server_db("work_orders");
          db.put(doc)
            .then(function(response) {
              console.log("Status updated in local DB:", response);
              if (response.ok) {
                console.log("Document successfully updated.");
              }
            })
            .catch(function(err) {
              instance.snackbar_text =
                "Error: Please Check Your Internet " + JSON.stringify(err);
              instance.snackbar = true;
              return;
            });
        }
      } catch (error) {
        console.error("Error creating visit:", error);
        this.snackbar = true;
        this.snackbar_text = "Failed to send data";
      }

      this.confirmDialog = false;
    },
    getColor: function(status) {
      if (status) {
        if (status.toLowerCase().startsWith("un")) return "red";
        else if (status == "") return "red";
        else if (status == "cancelled") return "grey";
        else if (status == "Finished") return "green";
        else if (status == "assigned") return "blue";
        else if (status == "billed") return "green";
        else if (status == "waiting") return "grey";
        else return "orange";
      } else {
        return "red";
      }
    },
    getServerStatusColor: function(status) {
      if (status) {
        if (status.toLowerCase().indexOf("not") > -1) return "red";
        if (status.toLowerCase().startsWith("un")) return "red";
        else if (status == "") return "red";
        else if (status == "waiting") return "blue";
        else if (status == "cancelled") return "grey";
        else return "green";
      } else {
        return "red";
      }
    },
    getStatus: function(status) {
      if (status == "Received") {
        return "Unbilled";
      }
      return status;
    },
    debounce_search: Util.debounce(function() {
      this.search_details();
    }, 500),
    search_details: function() {
      this.tableData = Util.search(this.work_orders, this.search.toLowerCase());
    },
    bill_order: function(item) {
      this.bill_item = item;
      this.billDialog = true;
    },
    doBillRegister: async function() {
      let result = this.$refs.enter_bill_form.validate();
      if (result) {
        let doc = this.bill_item;
        doc.server_status = "Billed";
        doc.bill_number = this.bill_number.toUpperCase();
        doc.lab_number = this.lab_number;
        doc = await this.update_billing(doc);
        let instance = this;
        //let db = await DBHandler.getDb("work_orders");
        let db = await WorkOrderDB.get_server_db("work_orders");
        db.put(doc)
          .then(function(response) {
            console.log(response);
            if (response.ok) {
              instance.snackbar_text = "Updated Successfully";
              instance.snackbar = true;
            }
          })
          .catch(function(err) {
            instance.snackbar_text =
              "Error: Please Check Your Internet :" + JSON.stringify(err);
            instance.snackbar = true;
            return;
          });
        this.bill_item = null;
        this.billDialog = false;
      }
    },
    update_billing: async function(work_order_doc) {
      let billing_db = await couch_db.get_db("andrsn_billing");
      let v_date = work_order_doc.appointment_date;
      let bill_no = work_order_doc.bill_number;
      let cal = moment(v_date, "DD-MM-YYYY");
      v_date = cal.format("YYYY-MM-DD");
      let id = "billing:" + v_date + ":" + bill_no.trim();
      try {
        let billing_doc = await billing_db.get(id);
        if (billing_doc) {
          work_order_doc.status_in_number = billing_doc.status_in_number;
          work_order_doc.report_status = billing_doc.report_status;
          work_order_doc.report_path = billing_doc.report_path;
          let billed_by = billing_doc.bill_details.BillUser;
          let billed_time = billing_doc.bill_details.VisitDate;
          let calendar = moment(billed_time);
          billed_time = calendar.format("MMMM DD, h:mm a");
          let workorder_billed =
            billed_time + "-" + billed_by + "- Work Order Billed";
          if (work_order_doc.time_line) {
            work_order_doc.time_line.push(workorder_billed);
          }
          billing_doc.doc_id = work_order_doc._id;
          let doc_dbs = Util.getFromSession("doc_dbs");
          let tenant_name = Util.resolve_name(doc_dbs);
          billing_doc.tenant_db_name = tenant_name;
          billing_db
            .put(billing_doc)
            .then(function(response) {
              console.log(response);
              if (response.ok) {
                console.log("Successfully updated billing doc:" + id);
              }
            })
            .catch(function(err) {
              console.log(
                "Error while updating billing doc:" + id + " > " + err
              );
            });
        }
      } catch (e) {
        console.error("Error while processing billing doc: " + id);
      }
      return work_order_doc;
    },
    getPrescrptionFileNames: function(name) {
      let aName = [];
      let retval = "";
      if (name.indexOf(",") > -1) {
        aName = name.split(",");
      } else {
        aName.push(name);
      }
      if (aName.length > 1) {
        retval = aName.length + " files.";
        return retval;
      } else {
        return Util.getName(name);
      }
    },
    get_name: function(name) {
      return Util.getName(name);
    },
    download_files: function(name) {
      let pres_array = [];
      let aName = [];
      if (name.indexOf(",") > -1) {
        aName = name.split(",");
      } else {
        aName.push(name);
      }
      for (let i = 0; i < aName.length; i++) {
        let fName = aName[i];
        pres_array.push(fName);
      }
      this.pres_array = pres_array;
      this.file_dialog = true;
    },
    download_id: function(id_loc, mode) {
      let item = id_loc + "";
      let dArray = [];
      if (item.indexOf(" | ") > -1) {
        dArray = item.split(" | ");
      } else {
        dArray[0] = "homecollection";
        dArray[1] = item;
      }
      let jwt_token = Util.getFromSession("pg_admin");
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token,
      };
      let name = dArray[1];
      if (name.indexOf("/") > -1) {
        name = name.substring(name.lastIndexOf("/") + 1);
      }
      if (mode == "view") {
        if (name.endsWith(".pdf")) {
          this.s3_url = id_loc;
          this.show_report = true;
        } else {
          this.s3_url = id_loc;
          this.show_img = true;
        }
        return;
      }
      this.overlay_dk = true;
      let instance = this;
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
        .then(async function(response) {
          //console.log(response.data);
          if (mode == "download") {
            fileDownload(response.data, name);
          }
          instance.overlay_dk = false;
        })
        .catch(function(error) {
          console.log("error:" + error);
          instance.overlay_dk = false;
          alert(error);
        });
    },
    close_download_dialog: function() {
      this.file_dialog = false;
    },
    pdf_close: function() {
      this.show_report = false;
    },
    img_close: function() {
      this.show_img = false;
    },
    getClientStatus: function(item) {
      let ret = "";
      if (item.urgent) {
        ret += "Urgent ";
      }
      if (item.vip_client) {
        ret += "VIP ";
      }
      if (item.credit == 1) {
        ret += "Credit ";
      } else if (item.credit == 2) {
        ret += "Trial ";
      }
      if (item.b2b_client_id) {
        ret += "B2B";
      }
      return ret;
    },
  },
};
</script>
